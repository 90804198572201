import(/* webpackMode: "eager", webpackExports: ["HighlightInit"] */ "/app/node_modules/@highlight-run/next/dist/next-client.js");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/app/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/utils/fonts.ts\",\"import\":\"Nunito\",\"arguments\":[{\"subsets\":[\"latin\",\"latin-ext\"],\"variable\":\"--font-nunito\"}],\"variableName\":\"nunito\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/utils/fonts.ts\",\"import\":\"Caveat\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-caveat\",\"weight\":\"400\"}],\"variableName\":\"caveat\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/utils/fonts.ts\",\"import\":\"Rubik\",\"arguments\":[{\"subsets\":[\"latin\",\"latin-ext\"],\"variable\":\"--font-rubik\"}],\"variableName\":\"rubik\"}");
;
import(/* webpackMode: "eager" */ "/app/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/loaders/AppProviders.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/variables.css");
