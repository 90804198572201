'use client';

import {I18nProvider} from 'react-aria';
import {ThemeProvider} from 'next-themes';
import {useRouter} from 'next/navigation';
import {Provider as JotaiProvider} from 'jotai';
import {RouterProvider} from 'react-aria-components';
import {domAnimation, LazyMotion} from 'motion/react';
import ReactQueryProvider from './ReactQueryProvider';

declare module 'react-aria-components' {
  interface RouterConfig {
    routerOptions: NonNullable<Parameters<ReturnType<typeof useRouter>['push']>[1]>;
  }
}

type Props = {
  children: React.ReactNode;
};

const AppProviders = ({children}: Props) => {
  const router = useRouter();

  return (
    <ThemeProvider attribute="class">
      <JotaiProvider>
        <ReactQueryProvider>
          <I18nProvider locale="en-US">
            <LazyMotion features={domAnimation} strict>
              <RouterProvider navigate={router.push}>{children}</RouterProvider>
            </LazyMotion>
          </I18nProvider>
        </ReactQueryProvider>
      </JotaiProvider>
    </ThemeProvider>
  );
};

export default AppProviders;
